<template>
    <b-modal
        id="modal-datos-comprobante"
        ref="my-modal"
        :title="titulo"
        no-close-on-backdrop
        ok-title="Aceptar"
        cancel-variant="outline-secondary"
        @close="cerrarModal"
    >

        <b-form>
            <b-row>
                <b-col cols="12">
                    <b-img
                        :src="item.archivo"
                        width="470"
                    />
                </b-col>
                <b-col cols="4">
                    <b-form-group
                        label-for="gestion"
                    >
                    <label >Nro. Comprobante</label>
                    <span>{{ item.nro_comprobante}}</span>    
                    </b-form-group>
                </b-col>
                
            </b-row>
        </b-form>

    </b-modal>
</template>
<script>
import axiosIns from '@/libs/axios'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BModal, BButton, VBModal, BFormGroup, BRow, BCol, BForm, BImg } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'

export default {
    components: {
        BButton,
        BModal,
        BFormGroup,
        BForm,
        BRow,
        BCol,
        BImg
    },
    props:[
        'item'
    ],
    directives: {
        'b-modal': VBModal,
        Ripple,
    },
    watch: {
        
    },
    data () {
        return {
            
            titulo:'Datos del Comprobante',
            required,
            submitted: false,
            errores:[],
        }
    },
    methods:{
        cerrarModal(){
            this.$emit('cerrarComponente');
        },
        actualizarLista(item){
            this.$emit('actualizarLista',item);
            this.submitted = false;
        },
    }
}
</script>