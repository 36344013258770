<template>
    <b-modal
        id="modal-concepto-pago"
        ref="my-modal"
        :title="titulo"
        no-close-on-backdrop
        ok-title="Guardar"
        cancel-variant="outline-secondary"
        cancel-title="Cerrar"
        @cancel="cerrarModal"
        @close="cerrarModal"
        @ok.prevent="validar"
    >

     <validation-observer ref="simpleRules">
        <b-form>
            <b-row>

                <!-- nombre -->
                <b-col cols="8">
                    <b-form-group
                        label-for="Nombre"
                    >
                    <label >Nombre <span class="text-danger">(*)</span></label>
                        <validation-provider
                        #default="{ errors }"
                        name="Nombre"
                        rules="required|min:4"
                        >
                        <b-form-input
                            id="nombre"
                            v-model="nombre"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Nombre del concepto de pago"
                        />
                        <!-- :state="(errors.length > 0 || errores.nombre) ? false:null" -->
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small v-for="error in errores.nombre" :key="error" class="text-danger">{{ error }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <!-- Gestion -->
                <b-col cols="4">
                    <b-form-group
                        label-for="gestion"
                    >
                    <label >Gestión <span class="text-danger">(*)</span></label>
                        <validation-provider
                            #default="{ errors }"
                            name="gestion" 
                            rules="required"
                            >
                            <b-form-input
                                id="gestion"
                                v-model="gestion"
                                type="number"
                                min="2000"
                                max="2100"
                                placeholder="2024"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                            <small v-for="error in errores.gestion" :key="error" class="text-danger">{{ error }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <!-- Monto -->
                <b-col cols="4">
                    <b-form-group
                        label="Monto"
                        label-for="monto"
                    >
                        <validation-provider
                            #default="{ errors }"
                            name="Monto"
                            rules="required"
                            >
                            <b-form-input
                                id="monto"
                                v-model="monto"
                                :state="errors.length > 0 ? false:null"
                                placeholder="0.0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                            <small v-for="error in errores.monto" :key="error" class="text-danger">{{ error }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <!-- Tipo de Cobro -->
                <b-col cols="4">
                    <b-form-group
                        label="Tipo de Cobro"
                        label-for="tipo_cobro"
                    >
                        <b-form-select
                        v-model="tipo_cobro"
                        :options="tiposCobroOpciones"
                        />
                        <small v-for="error in errores.tipo_cobro" :key="error" class="text-danger">{{ error }}</small>
                    </b-form-group>
                </b-col>
                <!-- Obligatorio -->
                <b-col cols="4">
                    <b-form-group
                        label="Obligatorio"
                        label-for="obligatorio"
                    >
                        <b-form-select
                        v-model="obligatorio"
                        :options="obligatorioOpciones"
                        />
                        <small v-for="error in errores.obligatorio" :key="error" class="text-danger">{{ error }}</small>
                    </b-form-group>
                </b-col>
                <!-- Estado -->
                <b-col cols="4">
                    <b-form-group
                        label="Estado"
                        label-for="Estado"
                    >
                        <b-form-select
                        v-model="estado"
                        :options="estadoOpciones"
                        />
                        <small v-for="error in errores.descripcion" :key="error" class="text-danger">{{ error }}</small>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-form>
    </validation-observer>

    </b-modal>
</template>
<script>
import axiosIns from '@/libs/axios'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BModal, BButton, VBModal, BAlert, BFormGroup, BFormInput, BRow, BCol, BForm, BFormSelect } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'

export default {
    components: {
        BButton,
        BModal,
        BAlert,
        BFormGroup,
        BFormInput,
        ValidationProvider,
        ValidationObserver,
        BForm,
        BRow,
        BCol,
        BFormSelect 
    },
    props:[
        'accion','item'
    ],
    directives: {
        'b-modal': VBModal,
        Ripple,
    },
    watch: {
        accion: function () {
            this.errores=[]; 
            if (this.accion) {
                this.abrir(this.accion,this.item);
            }
        },
        nombre: function () { 
            this.errores.nombre=[];
        },
        descripcion: function () { 
            this.errores.descripcion=[];
        },
    },
    data () {
        return {
            id:null,
            nombre:'',
            monto:0.0,
            tipo_cobro:'MENSUAL',
            gestion: new Date().getFullYear(),
            obligatorio: 1,
            estado:1,
            
            tiposCobroOpciones:[
                { value: 'MENSUAL', text: 'MENSUAL'}, 
                { value: 'UNICO', text: 'UNICO'}
            ],

            obligatorioOpciones:[
               { value: 1, text: 'Si' },
               { value: 2, text: 'No' },
            ],

            estadoOpciones:[
               { value: 1, text: 'Activo' },
               { value: 2, text: 'Inactivo' },
               { value: 3, text: 'Baja' }
            ],

            titulo:'',
            required,
            submitted: false,
            errores:[],
        }
    },
    methods:{
        validar() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    if (!this.submitted) {
                        this.submitted = true;
                        if(this.titulo=='Nuevo Concepto de Pago'){
                            this.registrar();
                        }else{
                            this.actualizar();
                        }
                    }
                }
                else{
                    console.log('error al validar');
                }
            })
        },
        abrir(accion,data=[]){
            switch(accion){
                case "registrar":
                {
                    this.titulo='Nuevo Concepto de Pago';                    
                    this.nombre = '';
                    this.monto = null;
                    this.tipo_cobro = 'MENSUAL';
                    this.gestion = null;
                    this.obligatorio = 1;
                    this.estado = 1;

                    break;
                }
                case "actualizar":
                {
                    this.titulo='Editar Concepto de Pago';
                    this.id=data['id'];                    
                    this.nombre = data['nombre'];
                    this.monto = data['monto'];
                    this.tipo_cobro = data['tipo_cobro'];
                    this.gestion = data['gestion'];
                    this.obligatorio = data['obligatorio'];
                    this.estado = data['estado'];

                    break;	
                }
            }
        },
        cerrarModal(){
            this.$emit('cerrarComponente');
        },
        actualizarLista(item){
            this.$emit('actualizarLista',item);
            this.submitted = false;
        },
        registrar(){
            axiosIns.post('/admin/concepto-pagos',{
                'nombre':this.nombre,
                'monto':this.monto,
                'tipo_cobro':this.tipo_cobro,
                'gestion':this.gestion,
                'obligatorio':this.obligatorio,
                'estado':this.estado
            })
            .then(res => {
                this.$nextTick(() => {
                    this.$refs['my-modal'].toggle('#toggle-btn')
                })
                this.actualizarLista(res.data);
                this.errores=[];
            })
            .catch(err =>{
                this.submitted = false;
                this.errores=err.response.data.errors;
            });
        },
        actualizar(){
            axiosIns.put('/admin/concepto-pagos/'+this.id,{
                'nombre':this.nombre,
                'monto':this.monto,
                'tipo_cobro':this.tipo_cobro,
                'gestion':this.gestion,
                'obligatorio':this.obligatorio,
                'estado':this.estado
            })
            .then(res => {
                this.$nextTick(() => {
                    this.$refs['my-modal'].toggle('#toggle-btn')
                })
                this.actualizarLista();
                this.errores=[];
            })
            .catch(err =>{
                this.submitted = false;
                this.errores=err.response.data.errors;
            });
        }
    }
}
</script>