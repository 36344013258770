import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { 
      path: '/', 
      redirect: { name: 'login' } 
    },
    {
      path: '/admin/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
      
    },
    {
      path: '/admin/escritorio',
      name: 'escritorio',
      component: () => import('@/views/Escritorio.vue'),
    },
    /*{
      path: '/second-page',
      name: 'second-page',
      component: () => import('@/views/SecondPage.vue'),
    },*/

    /* APLICACIONES */
      {
        path: '/admin/certificaciones',
        name: 'certificaciones',
        component: () => import('@/views/aplicaciones/certificaciones/Index.vue'),
        meta: {
          slug: 'certificados.ver',
        },
      },
      {
        path: '/admin/pagos-aportes',
        name: 'pagos-aportes',
        component: () => import('@/views/aplicaciones/pagos/Index-Aporte.vue'),
        meta: {
          slug: 'pago.ver',
        },
      },
      {
        path: '/admin/pagos-unico',
        name: 'pagos-unico',
        component: () => import('@/views/aplicaciones/pagos/Index-Unico.vue'),
        meta: {
          slug: 'pago.ver',
        },
      },
      {
        path: '/admin/conceptos-pago',
        name: 'conceptos-pago',
        component: () => import('@/views/aplicaciones/configuracion/conceptosPago/Index.vue'),
        meta: {
          slug: 'concepto-pago.ver',
        },
      },
      /* ENTRADAS */
      {
        path: '/admin/entradas',
        name: 'entrada',
        component: () => import('@/views/aplicaciones/entradas/Index.vue'),
        meta: {
          slug: 'entrada.ver',
        },
      },
      {
        path: '/admin/entradas/nuevo',
        name: 'entrada-nuevo',
        component: () => import('@/views/aplicaciones/entradas/Formulario.vue'),
        meta: {
          slug: 'entrada.registrar',
        },
      },
      {
        path: '/admin/entradas/editar/:id',
        name: 'entrada-editar',
        component: () => import('@/views/aplicaciones/entradas/Formulario.vue'),
        meta: {
          slug: 'entrada.editar',
          navActiveLink: 'entrada-nuevo',
        },
      },
      /* NOTICIAS */
      {
        path: '/admin/noticias',
        name: 'noticia',
        component: () => import('@/views/aplicaciones/noticias/Index.vue'),
        meta: {
          slug: 'noticia.ver',
        },
      },
      {
        path: '/admin/noticias/nuevo',
        name: 'noticia-nuevo',
        component: () => import('@/views/aplicaciones/noticias/Formulario.vue'),
        meta: {
          slug: 'noticia.registrar',
        },
      },
      {
        path: '/admin/noticias/editar/:id',
        name: 'noticia-editar',
        component: () => import('@/views/aplicaciones/noticias/Formulario.vue'),
        meta: {
          slug: 'noticia.editar',
          navActiveLink: 'noticia-nuevo',
        },
      },
      /* CONVOCATORIAS */
      {
        path: '/admin/convocatorias',
        name: 'convocatorias',
        component: () => import('@/views/aplicaciones/convocatorias/Index.vue'),
        meta: {
          slug: 'convocatoria.ver',
        },
      },
      {
        path: '/admin/convocatorias/nuevo',
        name: 'convocatoria-nuevo',
        component: () => import('@/views/aplicaciones/convocatorias/Formulario.vue'),
        meta: {
          slug: 'convocatoria.registrar',
        },
      },
      {
        path: '/admin/convocatorias/editar/:id',
        name: 'convocatoria-editar',
        component: () => import('@/views/aplicaciones/convocatorias/Formulario.vue'),
        meta: {
          slug: 'convocatoria.editar',
          navActiveLink: 'convocatoria-nuevo',
        },
      },
      //Organigrama
      {
        path: '/admin/departamentos',
        name: 'departamentos',
        component: () => import('@/views/aplicaciones/organigrama/departamentos/Index.vue'),
        meta: {
          slug: 'departamentos',
        },
      },
      {
        path: '/admin/organigramas',
        name: 'organigramas',
        component: () => import('@/views/aplicaciones/organigrama/Index.vue'),
        meta: {
          slug: 'departamentos',
        },
      },
      {
        path: '/admin/organigramas/nuevo',
        name: 'organigrama-nuevo',
        component: () => import('@/views/aplicaciones/organigrama/Form.vue'),
        meta: {
          slug: 'departamentos',
        },
      },
      {
        path: '/admin/organigramas/editar/:id',
        name: 'organigrama-editar',
        component: () => import('@/views/aplicaciones/organigrama/Form.vue'),
        meta: {
          slug: 'departamentos',
        },
      },
      /* CONFIGURACION DE APP */
      {
        path: '/admin/tipo-entradas',
        name: 'tipoEntrada',
        component: () => import('@/views/aplicaciones/configuracion/tipoEntrada/Index.vue'),
        meta: {
          slug: 'tipo_entradas',
        },
      },
      {
        path: '/admin/categorias',
        name: 'categoria',
        component: () => import('@/views/aplicaciones/configuracion/categoria/Index.vue'),
        meta: {
          slug: 'categorias',
        },
      },
      {
        path: '/admin/categoria/noticias',
        name: 'categoria-noticia',
        component: () => import('@/views/aplicaciones/configuracion/categoria/Noticia.vue'),
        meta: {
          slug: 'categorias',
        },
      },
      {
        path: '/admin/categoria/estadisticas',
        name: 'categoria-estadistica',
        component: () => import('@/views/aplicaciones/configuracion/categoria/Estadistica.vue'),
        meta: {
          slug: 'categorias',
        },
      },
      {
        path: '/admin/etiquetas',
        name: 'etiqueta',
        component: () => import('@/views/aplicaciones/configuracion/etiqueta/Index.vue'),
        meta: {
          slug: 'etiquetas',
        },
      },
      //fin de configuraciones configuraciones de aplicaciones
      {
        path: '/admin/medios',
        name: 'medios',
        component: () => import('@/views/aplicaciones/medios/Index.vue'),
        meta: {
          slug: 'medios',
        },
      },
      //Fiscalias
      {
        path: '/admin/fiscalias',
        name: 'fiscalias',
        component: () => import('@/views/aplicaciones/fiscalias/Index.vue'),
        meta: {
          slug: 'asientos_fiscales',
        },
      },
      {
        path: '/admin/fiscalias/nuevo',
        name: 'fiscalia-nuevo',
        component: () => import('@/views/aplicaciones/fiscalias/Form.vue'),
        meta: {
          slug: 'asientos_fiscales',
        },
      },
      {
        path: '/admin/fiscalias/editar/:id',
        name: 'fiscalia-editar',
        component: () => import('@/views/aplicaciones/fiscalias/Form.vue'),
        meta: {
          slug: 'asientos_fiscales',
          navActiveLink: 'fiscalia-nuevo',
        },
      },
      {
        path: '/admin/fiscalias/show/:id',
        name: 'fiscalia-ver',
        component: () => import('@/views/aplicaciones/fiscalias/Show.vue'),
        meta: {
          slug: 'asientos_fiscales',
        },
      },
      // Paginas
      {
        path: '/admin/paginas',
        name: 'paginas',
        component: () => import('@/views/aplicaciones/paginas/Index.vue'),
        meta: {
          slug: 'pagina.ver',
        },
      },
      {
        path: '/admin/paginas/nuevo',
        name: 'pagina-nuevo',
        component: () => import('@/views/aplicaciones/paginas/Form.vue'),
        meta: {
          slug: 'pagina.registrar',
        },
      },
      {
        path: '/admin/pagina/editar/:id',
        name: 'pagina-editar',
        component: () => import('@/views/aplicaciones/paginas/Form.vue'),
        meta: {
          slug: 'pagina.editar',
          navActiveLink: 'pagina-nuevo',
        },
        
        
      },

      //Eventos
      {
        path: '/admin/eventos',
        name: 'eventos',
        component: () => import('@/views/aplicaciones/eventos/Index.vue'),
        meta: {
          slug: 'evento.ver',
        },
      },
      {
        path: '/admin/eventos/nuevo',
        name: 'evento-nuevo',
        component: () => import('@/views/aplicaciones/eventos/Form.vue'),
        meta: {
          slug: 'evento.registrar',
        },
      },
      {
        path: '/admin/eventos/nuevo',
        name: 'evento-wizard-nuevo',
        component: () => import('@/views/aplicaciones/eventos/FormWizard.vue'),
        meta: {
          slug: 'evento.registrar',
        },
      },
      {
        path: '/admin/eventos/editar/:id',
        name: 'evento-editar',
        component: () => import('@/views/aplicaciones/eventos/Form.vue'),
        meta: {
          slug: 'evento.editar',
          navActiveLink: 'evento-nuevo',
        },
      },
      //Biblioteca libros
      {
        path: '/admin/biblioteca',
        name: 'biblioteca',
        component: () => import('@/views/aplicaciones/biblioteca/Index.vue'),
        meta: {
          slug: 'biblioteca',
        },
      },
      {
        path: '/admin/biblioteca/nuevo',
        name: 'biblioteca-nuevo',
        component: () => import('@/views/aplicaciones/biblioteca/Formulario.vue'),
        meta: {
          slug: 'biblioteca',
        },
      },
      {
        path: '/admin/biblioteca/editar/:id',
        name: 'biblioteca-editar',
        component: () => import('@/views/aplicaciones/biblioteca/Formulario.vue'),
        meta: {
          slug: 'biblioteca',
          navActiveLink: 'biblioteca-nuevo',
        },
      },
      /* galeria */
      
      {
        path: '/admin/galeria',
        name: 'galeria',
        component: () => import('@/views/aplicaciones/galeria/Index.vue'),
        meta: {
          slug: 'galeria',
        },
      },
      {
        path: '/admin/galeria/nuevo',
        name: 'galeria-nuevo',
        component: () => import('@/views/aplicaciones/galeria/Formulario.vue'),
        meta: {
          slug: 'galeria',
        },
      },
      {
        path: '/admin/galeria/editar/:id',
        name: 'galeria-editar',
        component: () => import('@/views/aplicaciones/galeria/Formulario.vue'),
        meta: {
          slug: 'galeria',
          navActiveLink: 'galeria-nuevo',
        },
      },
    /* FIN APLICACIONES */

       //sliders
       {
        path: '/admin/sliders/nuevo',
        name: 'slider-nuevo',
        component: () => import('@/views/apariencia/sliders/Form.vue'),
        meta: {
          slug: 'sliders',
        },
      },
      {
        path: '/admin/sliders',
        name: 'sliders',
        component: () => import('@/views/apariencia/sliders/Index.vue'),
        meta: {
          slug: 'sliders',
        },
      },
      {
        path: '/admin/sliders/editar/:id',
        name: 'slider-editar',
        component: () => import('@/views/apariencia/sliders/Form.vue'),
        meta: {
          slug: 'sliders',
        },
      },

    //popups
    {
      path: '/admin/popup/nuevo',
      name: 'popup-nuevo',
      component: () => import('@/views/apariencia/popups/Form.vue'),
      meta: {
        slug: 'popups',
      },
    },
    {
      path: '/admin/popups',
      name: 'popups',
      component: () => import('@/views/apariencia/popups/Index.vue'),
      meta: {
        slug: 'popups',
      },
    },
    {
      path: '/admin/popups/editar/:id',
      name: 'popup-editar',
      component: () => import('@/views/apariencia/popups/Form.vue'),
      meta: {
        slug: 'popups',
      },
    },
      //menus
    {
      path: '/admin/menus/nuevo',
      name: 'menu-nuevo',
      component: () => import('@/views/apariencia/menus/Form.vue'),
      meta: {
        slug: 'menus',
      },
    },
    {
      path: '/admin/menus',
      name: 'menus',
      component: () => import('@/views/apariencia/menus/Index.vue'),
      meta: {
        slug: 'menus',
      },
    },
    {
      path: '/admin/menus/editar/:id',
      name: 'menu-editar',
      component: () => import('@/views/apariencia/menus/Form.vue'),
      meta: {
        slug: 'menus',
      },
    },
    // enlaces
    {
      path: '/admin/enlaces/grupo/nuevo',
      name: 'grupo-enlace-nuevo',
      component: () => import('@/views/apariencia/enlaces/Form.vue'),
      meta: {
        slug: 'enlaces',
      },
    },
    {
      path: '/admin/enlaces',
      name: 'enlaces',
      component: () => import('@/views/apariencia/enlaces/Index.vue'),
      meta: {
        slug: 'enlaces',
      },
    },
    {
      path: '/admin/grupo/enlaces',
      name: 'grupo-enlaces',
      component: ()=>import('@/views/apariencia/enlaces/Indexgroup.vue'),
      meta: {
        slug: 'enlaces',
      },
    },
    {
      path: '/admin/grupo/enlaces/editar/:id',
      name: 'grupo-enlace-editar',
      component: () => import('@/views/apariencia/enlaces/Form.vue'),
      meta: {
        slug: 'enlaces',
      },
    },


    /* PODCAST */
    {
      path: '/admin/podcast',
      name: 'podcast',
      component: () => import('@/views/podcast/Index.vue'),
      meta: {
        slug: 'podcasts',
      },
    },

    /* VIDEOS */
    {
      path: '/admin/videos',
      name: 'video',
      component: () => import('@/views/videos/Index.vue'),
      meta: {
        slug: 'videos',
      },
    },
    {
      path: '/admin/videos/playlist/:id',
      name: 'video-playlist',
      component: () => import('@/views/videos/PlayList.vue'),
      meta: {
        slug: 'videos',
        navActiveLink: 'video',
      },
    },

    /* TRANSMISIONES */
    {
      path: '/admin/transmisiones',
      name: 'transmision',
      component: () => import('@/views/transmisiones/Index.vue'),
      meta: {
        slug: 'transmisiones',
      },
    },

    /* CONFIGURACION */
      // Personalizar
      {
        path: '/admin/personalizar',
        name: 'personalizar',
        component: () => import('@/views/personalizar/Index.vue'),
        meta: {
          slug: 'personalizar',
        },
      },
      // fin personalizar
      /* USUARIOS */
      {
        path: '/admin/usuarios',
        name: 'usuario',
        component: () => import('@/views/configuracion/usuarios/Index.vue'),
        meta: {
          slug: 'usuarios',
        },
      },
      /* USUARIOS */
      {
        path: '/admin/roles-permisos',
        name: 'rol',
        component: () => import('@/views/configuracion/roles/Index.vue'),
        meta: {
          slug: 'roles',
        },
      },
      {
        path: '/admin/roles-permisos/nuevo',
        name: 'rol-nuevo',
        component: () => import('@/views/configuracion/roles/Formulario.vue'),
        meta: {
          slug: 'roles',
          navActiveLink: 'rol',
        },
      },
      {
        path: '/admin/roles-permisos/editar/:id',
        name: 'rol-editar',
        component: () => import('@/views/configuracion/roles/Formulario.vue'),
        meta: {
          slug: 'roles',
          navActiveLink: 'rol',
        },
      },
      {
        path: '/admin/backups',
        name: 'backup',
        component: () => import('@/views/configuracion/backups/Index.vue'),
        meta: {
          slug: 'backups',
        },
      },
      {
        path: '/admin/perfil',
        name: 'perfil',
        component: () => import('@/views/configuracion/usuarios/Perfil.vue'),
      },
      {
        path: '/admin/auditoria',
        name: 'auditoria',
        component: () => import('@/views/configuracion/auditoria/Index.vue'),
        meta: {
          slug: 'auditoria',
        },
      },
      {
        path: '/admin/estadisticas',
        name: 'estadistica',
        component: () => import('@/views/aplicaciones/estadisticas/Index.vue'),
        meta: {
          slug: 'estadisticas',
        },
      },
      {
        path: '/admin/estadisticas/nuevo',
        name: 'estadistica-nuevo',
        component: () => import('@/views/aplicaciones/estadisticas/Formulario.vue'),
        meta: {
          slug: 'estadisticas',
        },
      },
      {
        path: '/admin/estadisticas/editar/:id',
        name: 'estadistica-editar',
        component: () => import('@/views/aplicaciones/estadisticas/Formulario.vue'),
        meta: {
          slug: 'estadisticas',
          navActiveLink: 'estadistica-nuevo',
        },
      },
    /* END CONFIGURACION */

    /*{
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },*/
    {
      path: '/not-authorized',
      name: 'not-authorized',
      component: () => import('@/views/NotAuthorized.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/account-desactive',
      name: 'account-desactive',
      component: () => import('@/views/AccountDesactive.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: () => import('@/views/ResetPassword.vue'),
      meta: {
        layout: 'full',
      },
    },    
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/verify-code',
      name: 'verify-code',
      component: () => import('@/views/VerifyCode.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const navigate = store.getters['auth/authenticated'];
  if (to.name=='login') {
    if(localStorage.getItem('token')){
      if (!navigate.estado) {
        return next({
          name:'account-desactive'
        })
      }
      return next({
        name:'escritorio'
      })
    }
  }
  if(!localStorage.getItem('token')){
    if (to.name!='login') {
      return next({
        name:'login'
      })
    }
  }
  if(localStorage.getItem('token')){
    if (!navigate.estado) {
      if (to.name!='account-desactive') {
        return next({
          name:'account-desactive'
        })
      }
    }
    // else if(navigate.reset_password){
    //   if (to.name!='reset-password') {
    //     return next({
    //       name:'reset-password'
    //     })
    //   }
    // }
  }
  if(to.meta.slug){
    if(!(navigate.permisos.indexOf(to.meta.slug) !== -1)){
      return next({
        name:'not-authorized'
      })
    }
  }
  next()
})
/*router.beforeEach((to, from, next) => {
  console.log(to.name)
  const token = localStorage.getItem('token')
    if(token || to.name === 'login'){
      next()
    }else{
      next({name: 'login'})
    }
  
});*/

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
