var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modal-pago",attrs:{"id":"modal-pago","title":"Registrar Pago","no-close-on-backdrop":"","ok-title":"Registrar","cancel-variant":"outline-secondary","cancel-title":"Cerrar"},on:{"cancel":_vm.cerrarModal,"close":_vm.cerrarModal,"ok":function($event){$event.preventDefault();return _vm.validar.apply(null, arguments)}}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-for":"afiliado"}},[_c('label',[_vm._v("Afiliado")]),_c('br'),(_vm.certificacion)?_c('p',[_vm._v(" "+_vm._s(_vm.certificacion.afiliado.apellidos)+" "+_vm._s(_vm.certificacion.afiliado.nombres)+" ")]):_vm._e()])],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Concepto Pago","label-for":"concepto_pago"}},[_c('validation-provider',{attrs:{"name":"concepto pago","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.conceptosPagoOpciones,"placeholder":"Seleccionar"},model:{value:(_vm.concepto_pago),callback:function ($$v) {_vm.concepto_pago=$$v},expression:"concepto_pago"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_vm._l((_vm.errores.concepto_pago),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}])})],1)],1),_c('b-col',{staticClass:"pt-0 pb-2",attrs:{"cols":"12"}},[_c('strong',{staticClass:"text-bold"},[_vm._v("Monto a Pagar: "+_vm._s(_vm.concepto_pago ? _vm.concepto_pago.monto : '0.0')+" Bs.")])]),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Tipo de Pago","label-for":"tipoPago"}},[_c('b-form-select',{attrs:{"options":_vm.tiposPagoOpciones},model:{value:(_vm.tipoPago),callback:function ($$v) {_vm.tipoPago=$$v},expression:"tipoPago"}}),_vm._l((_vm.errores.tipoPago),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})],2)],1),_c('b-col',{attrs:{"cols":"6"}},[(_vm.tipoPago != 'efectivo')?_c('b-form-group',{attrs:{"label":"Comprobante","label-for":"comprobante"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"id":"comprobante","size":"","variant":"outline-primary"},on:{"click":_vm.seleccionarImagenes}},[_vm._v(" Subir Comprobante ")]),_c('b-form-file',{staticClass:"mt-1",attrs:{"accept":"image/*","plain":"","hidden":"","id":"imageUpload"},on:{"change":_vm.subirImagenes},model:{value:(_vm.comprobante),callback:function ($$v) {_vm.comprobante=$$v},expression:"comprobante"}})],1):_vm._e()],1),_c('b-col',{attrs:{"cols":"6"}},[(_vm.tipoPago != 'efectivo')?_c('b-form-group',{attrs:{"label-for":"fecha_pago","label":"Fecha de Pago"}},[_c('validation-provider',{attrs:{"name":"fecha pago","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"fecha_pago","type":"date"},model:{value:(_vm.fecha_pago),callback:function ($$v) {_vm.fecha_pago=$$v},expression:"fecha_pago"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_vm._l((_vm.errores.fecha_pago),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}],null,false,3270269900)})],1):_vm._e()],1),_c('b-col',{attrs:{"cols":"6"}},[(_vm.tipoPago != 'efectivo')?_c('b-form-group',{attrs:{"label-for":"nro_comprobante","label":"Nro Comprobante"}},[_c('validation-provider',{attrs:{"name":"Nro comprobante","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nro_comprobante","type":"text"},model:{value:(_vm.nro_comprobante),callback:function ($$v) {_vm.nro_comprobante=$$v},expression:"nro_comprobante"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_vm._l((_vm.errores.nro_comprobante),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}],null,false,3090991478)})],1):_vm._e()],1),_c('b-col',{attrs:{"cols":"6"}},[(_vm.tipoPago != 'efectivo')?_c('b-form-group',{attrs:{"label-for":"nombre_depositante","label":"Nombre Depositante"}},[_c('validation-provider',{attrs:{"name":"nombre depositante","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nombre_depositante","type":"text"},model:{value:(_vm.nombre_depositante),callback:function ($$v) {_vm.nombre_depositante=$$v},expression:"nombre_depositante"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_vm._l((_vm.errores.nombre_depositante),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}],null,false,796447266)})],1):_vm._e()],1),_c('b-col',{attrs:{"cols":"4"}},[(_vm.tipoPago != 'efectivo')?_c('b-form-group',{attrs:{"label":"Estado de Pago","label-for":"verificado"}},[_c('b-form-checkbox',{attrs:{"id":"verificado","value":"1","unchecked-value":"0","switch":""},model:{value:(_vm.verificado),callback:function ($$v) {_vm.verificado=$$v},expression:"verificado"}},[_vm._v(" "+_vm._s(_vm.verificado == 1 ? 'Verificado' : 'Sin Verificar')+" ")])],1):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }