<template>
    <b-modal
        id="modal-pago"
        ref="my-modal"
        :title="titulo"
        no-close-on-backdrop
        ok-title="Registrar"
        cancel-variant="outline-secondary"
        cancel-title="Cerrar"
        size="lg"
        @cancel="cerrarModal"
        @close="cerrarModal"
        @ok.prevent="validar"
    >

    <validation-observer ref="simpleRules">
        <b-form>
            <b-row>
                <!-- afiliado -->
                <b-col cols="8">
                    <b-form-group
                        label-for="afiliado"
                    >
                    <label >Afiliado <span class="text-danger">(*)</span></label>
                        <validation-provider
                        #default="{ errors }"
                        name="afiliado"
                        rules="required"
                        >
                        <v-select
                            placeholder="Buscar afiliado"
                            label="nombre_completo"
                            v-model="afiliado"
                            :options="afiliadosSearch"
                            
                            @search="buscarAfiliado"
                            @input="listarGestiones"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small v-for="error in errores.afiliado" :key="error" class="text-danger">{{ error }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <!-- gestiones pendientes -->
                <b-col cols="4">
                    <b-form-group
                        label-for="gestion"
                    >
                        <label >Gestión <span class="text-danger">(*)</span></label>
                        <b-form-select
                            v-model="gestion"
                            :options="gestionOpciones"
                            placeholder="Seleccione"
                            @change="listarMesesPendientes"
                        />
                        <small v-for="error in errores.gestion" :key="error" class="text-danger">{{ error }}</small>
                    </b-form-group>                    
                </b-col>                
                <!-- Meses -->
                <b-col cols="12">
                    <b-form-group
                        label="Meses"
                        label-for="meses"
                    >
                        <validation-provider
                            #default="{ errors }"
                            name="Meses"
                            rules="required"
                            >
                                <b-form-checkbox-group
                                    id="meses"
                                    v-model="selectedMeses"
                                    :options="mesesPendientes"
                                    @input="updateSelectedMonths"
                                ></b-form-checkbox-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                            <small v-for="error in errores.meses" :key="error" class="text-danger">{{ error }}</small>
                        </validation-provider>
                    </b-form-group>  
                </b-col>
                <b-col cols="12" class="pt-0 pb-2">
                    <strong class="text-bold">Monto a Pagar: {{ montoTotal }} Bs.</strong>
                </b-col>
                <!-- Tipo de Pago -->
                <b-col cols="4">
                    <b-form-group
                        label="Tipo de Pago"
                        label-for="tipoPago"
                    >
                        <b-form-select
                        v-model="tipoPago"
                        :options="tiposPagoOpciones"
                        />
                        <small v-for="error in errores.tipoPago" :key="error" class="text-danger">{{ error }}</small>
                    </b-form-group>
                </b-col>
                <b-col cols="4">
                    <b-form-group
                        label="Comprobante"
                        label-for="comprobante"
                        v-if="tipoPago != 'efectivo'"
                    >
                        <b-button
                            id="comprobante"
                            size=""
                            class=""
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            variant="outline-primary"
                            @click="seleccionarImagenes"
                            >
                            Subir Comprobante
                        </b-button>
                        <b-form-file
                            @change="subirImagenes"
                            v-model="comprobante"
                            accept="image/*"
                            class="mt-1"
                            plain
                            hidden
                            id="imageUpload"
                        />
                    </b-form-group>
                </b-col>
                <b-col cols="4">
                    <b-form-group
                        label-for="nro_comprobante"
                        label="Nro Comprobante"
                        v-if="tipoPago != 'efectivo'"
                    >
                        <validation-provider
                            #default="{ errors }"
                            name="nro_comprobante" 
                            rules="required"
                            >
                            <b-form-input
                                id="nro_comprobante"
                                v-model="nro_comprobante"
                                type="text"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                            <small v-for="error in errores.nro_comprobante" :key="error" class="text-danger">{{ error }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col cols="4">
                    <b-form-group
                        label="Estado de Pago"
                        label-for="verificado"
                        v-if="tipoPago != 'efectivo'"
                    >
                        <b-form-checkbox
                            id="verificado"
                            v-model="verificado"
                            value="1"
                            unchecked-value="0"
                            switch
                        >
                            {{ verificado == 1 ? 'Verificado' : 'Sin Verificar' }}
                        </b-form-checkbox>
                    </b-form-group>                    
                </b-col>                
            </b-row>
        </b-form>
    </validation-observer>

    </b-modal>
</template>
<script>
import axiosIns from '@/libs/axios'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BModal, BButton, VBModal, BAlert, BFormGroup, BFormInput, BRow, BCol, BForm, BFormSelect, BFormCheckboxGroup, BFormFile, BFormCheckbox } from 'bootstrap-vue'
import vSelect from "vue-select";
import Ripple from 'vue-ripple-directive'
import { required } from '@validations';

export default {
    components: {
        BButton,
        BModal,
        BAlert,
        BFormGroup,
        BFormInput,
        ValidationProvider,
        ValidationObserver,
        BForm,
        BRow,
        BCol,
        BFormSelect,
        vSelect,
        BFormCheckboxGroup,
        BFormFile,
        BFormCheckbox
    },
    props:{
        certificado: null
    },
    directives: {
        'b-modal': VBModal,
        Ripple,
    },
    watch: {
        selectedMeses: function () {
            if(this.gestion != null && this.selectedMeses != null){
                this.montoTotal = parseFloat(this.gestion.monto) * this.selectedMeses.length;            
            }
        },
        
    },
    data () {
        return {
            titulo:'Registrar Pago',

            id:null,
            afiliado: null,
            afiliadosSearch: [],

            gestion:null,
            gestionOpciones: [],

            selectedMeses: [],
            mesesPendientes: [],

            montoTotal: 0.0,
            verificado: 0,

            tipoPago: 'efectivo',
            fecha_pago: new Date(),
            
            tiposPagoOpciones:[
                { value: 'efectivo', text: 'Efectivo'}, 
                { value: 'deposito', text: 'Depósito'}
            ],

            comprobante: null,
            nro_comprobante: null,

            required,
            
            submitted: false,
            errores:[],
        }
    },
    created(){
        // this.listarGestiones();
    },
    methods:{
        validar() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    if (!this.submitted) {
                        this.submitted = true;
                        this.registrar();
                    }
                }
                else{
                    console.log('error al validar');
                }
            })
        },
        
        reset(){
            this.afiliado = null;
            this.gestion = null;
            this.gestionOpciones = [];
            this.selectedMeses = [];
            this.mesesPendientes = [];
            this.montoTotal = 0;
            this.tipoPago = 'efectivo';
            this.verificado = 0;
            this.comprobante = null;
            this.nro_comprobante = null;
        },

        cerrarModal(){
            this.reset();
            this.$emit('cerrarComponente');
        },
        actualizarLista(item){
            this.$emit('actualizarLista',item);
            this.submitted = false;
        },
        buscarAfiliado(query) {
            var url= '/admin/buscar-afiliado?q='+query;
            axiosIns
            .get(url)
            .then((res) => {
                this.afiliadosSearch = res.data.map((afiliado) => ({
                ...afiliado,
                nombre_completo: `${afiliado.nombres} ${afiliado.apellidos} (${afiliado.rn})`, // Campo compuesto
                }));
            })
            .catch((err) => {
                console.log(err);
            });
        },
        listarGestiones() {
            if(this.afiliado){
                var url= '/admin/concepto-pagos/afiliado/'+this.afiliado.rn;
                axiosIns
                .get(url)
                .then((res) => {
                    this.gestionOpciones = res.data.map((gestion) => ({
                        value: gestion,
                        text: `${gestion.gestion}`,
                    }));                
                })
                .catch((err) => {
                    console.log(err);
                });
            }else{
                this.gestion = null;
                this.gestionOpciones = [];
                this.selectedMeses = [];
                this.mesesPendientes = [];
            }            
        },
        listarMesesPendientes(gestion){
            var url= '/admin/concepto-pagos/'+gestion.id+'/meses-pendientes/'+this.afiliado.rn;
            axiosIns
            .get(url)
            .then((res) => {
                this.mesesPendientes = res.data.map((mes) => ({
                    value: mes,
                    text: `${mes.nombre}`,
                }));
                this.selectedMeses = [];
            })
            .catch((err) => {
                console.log(err);
            });
        },

        seleccionarImagenes() {
            document.getElementById("imageUpload").click();
        },

        async subirImagenes(event) {
            console.log(event);    
        },

        registrar(){
            let formData = new FormData();
            formData.append('monto_total', parseFloat(this.montoTotal));
            // let fechaFormateada = new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate();
            // formData.append('fecha_pago', fechaFormateada); 
            formData.append('tipo_pago', this.tipoPago);
            formData.append('afiliado_rn', this.afiliado.rn);
            formData.append('verificado', this.tipoPago == 'efectivo' ? 1 : this.verificado);
            formData.append('comprobante', this.comprobante);
            formData.append('nro_comprobante', this.nro_comprobante);
            formData.append('concepto_pago_id', this.gestion.id);
            // formData.append('meses', this.selectedMeses.map(mes => mes.id));

            this.selectedMeses.forEach(mes => {
                formData.append('meses[]', mes.id);
            });

            console.log(formData);
            axiosIns.post('/admin/pagos/mensual',
            formData,{
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
            })
            .then(res => {
                this.$nextTick(() => {
                    this.$refs['my-modal'].toggle('#toggle-btn');
                })
                this.actualizarLista(res.data);
                this.errores=[];
                this.reset();
            })
            .catch(err =>{
                this.submitted = false;
                this.errores=err.response.data.errors;
            });
        },

        updateSelectedMonths() {
            this.selectedMeses.sort((a, b) => a.id - b.id);
            // const mesMax = this.selectedMeses[this.selectedMeses.length - 1];
            // console.log("mes mayor", mesMax)
            // console.log(this.selectedMeses)

            // this.selectedMeses = [];
            // const updatedSelection = this.mesesPendientes.filter((mes) => {
            //     return mes.value.id <= mesMax.id;
            // });

            // this.selectedMeses = updatedSelection.map(item => item.value);

        },
    }
}
</script>
<style lang="scss">
    #meses .custom-checkbox{
        width: 135px;
        margin: 5px 0;
    }
</style>