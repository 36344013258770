<template>
    <b-modal
        id="modal-certificacion"
        ref="my-modal"
        :title="titulo"
        no-close-on-backdrop
        ok-title="Guardar"
        cancel-variant="outline-secondary"
        cancel-title="Cerrar"
        @cancel="cerrarModal"
        @close="cerrarModal"
        @ok.prevent="validar"
    >

     <validation-observer ref="simpleRules">
        <b-form>
            <b-row>
                <!-- afiliado -->
                <b-col cols="12">
                    <b-form-group
                        label-for="afiliado"
                    >
                    <label >Afiliado <span class="text-danger">(*)</span></label>
                        <validation-provider
                        #default="{ errors }"
                        name="afiliado"
                        rules="required"
                        >
                        <v-select
                            placeholder="Buscar afiliado"
                            label="nombre_completo"
                            v-model="afiliado"
                            :options="afiliadosSearch"
                            :state="errors.length > 0 ? false:null"
                            @search="buscarAfiliado"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small v-for="error in errores.afiliado" :key="error" class="text-danger">{{ error }}</small>
                        </validation-provider>
                    </b-form-group>                    
                </b-col> 
                <!-- proyecto -->
                <b-col cols="12">
                    <b-form-group
                        label-for="proyecto"
                    >
                    <label >Proyecto <span class="text-danger">(*)</span></label>
                        <validation-provider
                        #default="{ errors }"
                        name="proyecto"
                        rules="required|min:4"
                        >
                        <b-form-input
                            id="proyecto"
                            v-model="proyecto"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Nombre del proyecto"
                        />
                        <!-- :state="(errors.length > 0 || errores.nombre) ? false:null" -->
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small v-for="error in errores.proyecto" :key="error" class="text-danger">{{ error }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group
                        label-for="cuce"
                    >
                    <label >CUCE <span class="text-danger">(*)</span></label>
                        <validation-provider
                        #default="{ errors }"
                        name="cuce"
                        rules="required|min:4"
                        >
                        <b-form-input
                            id="cuce"
                            v-model="cuce"
                            :state="errors.length > 0 ? false:null"
                            placeholder="CUCE"
                        />
                        <!-- :state="(errors.length > 0 || errores.nombre) ? false:null" -->
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small v-for="error in errores.cuce" :key="error" class="text-danger">{{ error }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group
                        label-for="nombre_entidad"
                    >
                    <label >Nombre Entidad <span class="text-danger">(*)</span></label>
                        <validation-provider
                        #default="{ errors }"
                        name="nombre entidad"
                        rules="required|min:4"
                        >
                        <b-form-input
                            id="nombre_entidad"
                            v-model="nombre_entidad"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Nombre de la entidad"
                        />
                        <!-- :state="(errors.length > 0 || errores.nombre) ? false:null" -->
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small v-for="error in errores.nombre_entidad" :key="error" class="text-danger">{{ error }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group
                        label-for="cargo"
                    >
                        <label >Cargo</label>
                        <b-form-input
                            id="cargo"
                            v-model="cargo"
                            placeholder="Nombre del cargo"
                        />
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group
                        label-for="razon_social"
                    >
                    <label >Razón Social <span class="text-danger">(*)</span></label>
                        <validation-provider
                        #default="{ errors }"
                        name="razon social"
                        rules="required|min:4"
                        >
                        <b-form-input
                            id="razon_social"
                            v-model="razon_social"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Razón social"
                        />
                        <!-- :state="(errors.length > 0 || errores.nombre) ? false:null" -->
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small v-for="error in errores.razon_social" :key="error" class="text-danger">{{ error }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-form>
    </validation-observer>

    </b-modal>
</template>
<script>
import axiosIns from '@/libs/axios'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BModal, BButton, VBModal, BAlert, BFormGroup, BFormInput, BRow, BCol, BForm, BFormSelect } from 'bootstrap-vue'
import vSelect from "vue-select";
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'

export default {
    components: {
        BButton,
        BModal,
        BAlert,
        BFormGroup,
        BFormInput,
        ValidationProvider,
        ValidationObserver,
        BForm,
        BRow,
        BCol,
        BFormSelect,
        vSelect
    },
    props:[
        'accion','item'
    ],
    directives: {
        'b-modal': VBModal,
        Ripple,
    },
    watch: {
        accion: function () {
            this.errores=[]; 
            if (this.accion) {
                this.abrir(this.accion,this.item);
            }
        },
        // nombre: function () { 
        //     this.errores.nombre=[];
        // },        
    },
    data () {
        return {
            titulo: null,
            id: null,
            afiliado: null,
            afiliadosSearch: [],

            proyecto: null,
            cuce: null,
            nombre_entidad: null,
            cargo: null,
            razon_social: null,
            estado:1,
            
            required,
            submitted: false,
            errores:[],
        }
    },
    methods:{
        validar() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    if (!this.submitted) {
                        this.submitted = true;
                        if(this.id == null){
                            this.registrar();
                        }else{
                            this.actualizar();
                        }
                    }
                }
                else{
                    console.log('error al validar');
                }
            })
        },
        abrir(accion,data=[]){
            switch(accion){
                case "registrar":
                {
                    this.titulo='Registrar Certificación';                    
                    break;
                }
                case "actualizar":
                {
                    this.titulo='Editar Certificación';
                    this.id=data['id'];                    
                    this.afiliado = data['afiliado'];
                    this.proyecto = data['proyecto'];
                    this.cuce = data['cuce'];
                    this.nombre_entidad = data['nombre_entidad'];
                    this.cargo = data['cargo'];
                    this.razon_social = data['razon_social'];
                    this.estado = data['estado'];

                    this.afiliado = {
                        ...this.afiliado,
                        nombre_completo: `${this.afiliado.nombres} ${this.afiliado.apellidos} (${this.afiliado.rn})`
                    };
                    this.buscarAfiliado(this.afiliado.rn);
                    break;	
                }
            }
        },
        buscarAfiliado(query) {
            var url= '/admin/buscar-afiliado?q='+query;
            axiosIns
            .get(url)
            .then((res) => {
                this.afiliadosSearch = res.data.map((afiliado) => ({
                ...afiliado,
                nombre_completo: `${afiliado.nombres} ${afiliado.apellidos} (${afiliado.rn})`, // Campo compuesto
                }));
            })
            .catch((err) => {
                console.log(err);
            });
        },
        cerrarModal(){
            this.reset();
            this.$emit('cerrarComponente');
        },
        actualizarLista(item){
            this.$emit('actualizarLista',item);
            this.submitted = false;
        },
        registrar(){
            const data = {
                afiliado_id: this.afiliado.id,
                proyecto: this.proyecto,
                cuce: this.cuce,
                nombre_entidad: this.nombre_entidad,
                cargo: this.cargo,
                razon_social: this.razon_social
            };

            axiosIns.post('/admin/certificacion', data)
            .then(res => {
                this.$nextTick(() => {
                    this.$refs['my-modal'].toggle('#toggle-btn')
                })
                this.actualizarLista(res.data);
                this.errores=[];
            })
            .catch(err =>{
                this.submitted = false;
                this.errores=err.response.data.errors;
            });
        },
        actualizar(){
            const data = {
                afiliado_id: this.afiliado.id,
                proyecto: this.proyecto,
                cuce: this.cuce,
                nombre_entidad: this.nombre_entidad,
                cargo: this.cargo,
                razon_social: this.razon_social
            };
            axiosIns.patch('/admin/certificacion/'+this.id, data)
            .then(res => {
                this.$nextTick(() => {
                    this.$refs['my-modal'].toggle('#toggle-btn')
                })
                this.actualizarLista();
                this.errores=[];
            })
            .catch(err =>{
                this.submitted = false;
                this.errores=err.response.data.errors;
            });
        }, 
        reset(){
            this.id = null;
            this.afiliado = null;
            this.proyecto = null;
            this.cuce = null;
            this.nombre_entidad = null;
            this.cargo = null;
            this.razon_social = null;
            this.errores = [];
        }
    }
}
</script>