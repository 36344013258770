<template>
    <b-modal
        id="modal-pago"
        ref="modal-pago"
        title="Registrar Pago"
        no-close-on-backdrop
        ok-title="Registrar"
        cancel-variant="outline-secondary"
        cancel-title="Cerrar"
        
        @cancel="cerrarModal"
        @close="cerrarModal"
        @ok.prevent="validar"
    >

    <validation-observer ref="simpleRules">
        <b-form>
            <b-row>
                <!-- afiliado -->
                <b-col cols="12">
                    <b-form-group
                        label-for="afiliado"
                    >
                        <label >Afiliado</label>
                        <br>
                        <p v-if="certificacion">
                            {{ certificacion.afiliado.apellidos }} {{ certificacion.afiliado.nombres }}
                        </p>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group
                        label="Concepto Pago"
                        label-for="concepto_pago"
                    >
                        <validation-provider
                            #default="{ errors }"
                            name="concepto pago" 
                            rules="required"
                        >
                            <b-form-select
                                v-model="concepto_pago"
                                :options="conceptosPagoOpciones"
                                placeholder="Seleccionar"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                            <small v-for="error in errores.concepto_pago" :key="error" class="text-danger">{{ error }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col cols="12" class="pt-0 pb-2">
                    <strong class="text-bold">Monto a Pagar: {{ concepto_pago ? concepto_pago.monto : '0.0' }} Bs.</strong>
                </b-col>
                <!-- Tipo de Pago -->
                <b-col cols="6">
                    <b-form-group
                        label="Tipo de Pago"
                        label-for="tipoPago"
                    >
                        <b-form-select
                        v-model="tipoPago"
                        :options="tiposPagoOpciones"
                        />
                        <small v-for="error in errores.tipoPago" :key="error" class="text-danger">{{ error }}</small>
                    </b-form-group>
                </b-col>
                
                <b-col cols="6">
                    <b-form-group
                        label="Comprobante"
                        label-for="comprobante"
                        v-if="tipoPago != 'efectivo'"
                    >
                        <b-button
                            id="comprobante"
                            size=""
                            class=""
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            variant="outline-primary"
                            @click="seleccionarImagenes"
                            >
                            Subir Comprobante
                        </b-button>
                        <b-form-file
                            @change="subirImagenes"
                            v-model="comprobante"
                            accept="image/*"
                            class="mt-1"
                            plain
                            hidden
                            id="imageUpload"
                        />
                    </b-form-group>
                </b-col>
                <b-col cols="6">
                    <b-form-group
                        label-for="fecha_pago"
                        label="Fecha de Pago"
                        v-if="tipoPago != 'efectivo'"
                    >
                        <validation-provider
                            #default="{ errors }"
                            name="fecha pago" 
                            rules="required"
                            >
                            <b-form-input
                                id="fecha_pago"
                                v-model="fecha_pago"
                                type="date"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                            <small v-for="error in errores.fecha_pago" :key="error" class="text-danger">{{ error }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col cols="6">
                    <b-form-group
                        label-for="nro_comprobante"
                        label="Nro Comprobante"
                        v-if="tipoPago != 'efectivo'"
                    >
                        <validation-provider
                            #default="{ errors }"
                            name="Nro comprobante" 
                            rules="required"
                            >
                            <b-form-input
                                id="nro_comprobante"
                                v-model="nro_comprobante"
                                type="text"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                            <small v-for="error in errores.nro_comprobante" :key="error" class="text-danger">{{ error }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col cols="6">
                    <b-form-group
                        label-for="nombre_depositante"
                        label="Nombre Depositante"
                        v-if="tipoPago != 'efectivo'"
                    >
                        <validation-provider
                            #default="{ errors }"
                            name="nombre depositante" 
                            rules="required"
                            >
                            <b-form-input
                                id="nombre_depositante"
                                v-model="nombre_depositante"
                                type="text"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                            <small v-for="error in errores.nombre_depositante" :key="error" class="text-danger">{{ error }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col cols="4">
                    <b-form-group
                        label="Estado de Pago"
                        label-for="verificado"
                        v-if="tipoPago != 'efectivo'"
                    >
                        <b-form-checkbox
                            id="verificado"
                            v-model="verificado"
                            value="1"
                            unchecked-value="0"
                            switch
                        >
                            {{ verificado == 1 ? 'Verificado' : 'Sin Verificar' }}
                        </b-form-checkbox>
                    </b-form-group>                    
                </b-col>
            </b-row>
        </b-form>
    </validation-observer>

    </b-modal>
</template>
<script>
import axiosIns from '@/libs/axios'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BModal, BButton, VBModal, BAlert, BFormGroup, BFormInput, BRow, BCol, BForm, BFormSelect, BFormCheckboxGroup, BFormFile, BFormCheckbox } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations';

export default {
    components: {
        BButton,
        BModal,
        BAlert,
        BFormGroup,
        BFormInput,
        ValidationProvider,
        ValidationObserver,
        BForm,
        BRow,
        BCol,
        BFormSelect,
        BFormCheckboxGroup,
        BFormFile,
        BFormCheckbox
    },
    props:{
        certificacion: null
    },
    directives: {
        'b-modal': VBModal,
        Ripple,
    },
    watch: {
        certificacion : function (){
            if(this.certificacion)
                this.getConceptosPago();
        }
    },
    data () {
        return {
            titulo:'Registrar Pago',

            id:null,
            afiliado: null,
            afiliadosSearch: [],

            montoTotal: 0.0,
            verificado: 0,

            concepto_pago: null,
            conceptosPagoOpciones: [],

            fecha_pago: new Date().toISOString().split('T')[0],
            tipoPago: 'efectivo',            
            tiposPagoOpciones:[
                { value: 'efectivo', text: 'Efectivo'}, 
                { value: 'deposito', text: 'Depósito'},
                { value: 'transferencia', text: 'Transferencia'}
            ],

            comprobante: null,
            nro_comprobante: null,
            nombre_depositante: null,

            required,
            submitted: false,
            errores:[],
        }
    },
    created(){
        
    },
    methods:{
        validar() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    if (!this.submitted) {
                        this.submitted = true;
                        this.registrar();
                    }
                }
                else{
                    console.log('error al validar');
                }
            })
        },
        
        reset(){
            this.afiliado = null;
            this.concepto_pago = null;
            this.tipoPago = 'efectivo';
            this.comprobante = null;
            this.fecha_pago = new Date().toISOString().split('T')[0];
            this.nro_comprobante = null;
            this.nombre_depositante = null;
            this.verificado = 0;
        },

        cerrarModal(){
            this.reset();
            this.$emit('cerrarComponente');
        },
        actualizarLista(item){
            this.$emit('actualizarLista',item);
            this.submitted = false;
        },
        buscarAfiliado(query) {
            var url= '/admin/buscar-afiliado?q='+query;
            axiosIns
            .get(url)
            .then((res) => {
                this.afiliadosSearch = res.data.map((afiliado) => ({
                ...afiliado,
                nombre_completo: `${afiliado.nombres} ${afiliado.apellidos} (${afiliado.rn})`, // Campo compuesto
                }));
            })
            .catch((err) => {
                console.log(err);
            });
        },
        getConceptosPago() {            
            const url= '/admin/certificacion-concepto-pagos';
            axiosIns
            .get(url)
            .then((res) => {
                this.conceptosPagoOpciones = res.data.map((concepto) => ({
                    value: concepto,
                    text: `${concepto.nombre}`,
                }));                
            })
            .catch((err) => {
                console.log(err);
            });                        
        },
        
        seleccionarImagenes() {
            document.getElementById("imageUpload").click();
        },

        async subirImagenes(event) {
            console.log(event);    
        },

        registrar(){
            let formData = new FormData();
            formData.append('concepto_pago_id', this.concepto_pago.id);
            formData.append('fecha_pago', this.fecha_pago); 
            formData.append('monto', parseFloat(this.concepto_pago.monto));
            formData.append('tipo_pago', this.tipoPago);
            formData.append('verificado', this.tipoPago == 'efectivo' ? 1 : this.verificado);
            formData.append('comprobante', this.comprobante);
            formData.append('nro_comprobante', this.nro_comprobante);
            formData.append('nombre_depositante', this.nombre_depositante);
            formData.append('certificacion_id', this.certificacion.id);
            formData.append('afiliado_rn', this.certificacion.afiliado.rn);

            axiosIns.post('/admin/pagos/unico',
            formData,{
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
            })
            .then(res => {
                this.$nextTick(() => {
                    this.$refs['modal-pago'].toggle('#toggle-btn');
                })
                this.actualizarLista(res.data);
                this.errores=[];
                this.reset();
            })
            .catch(err =>{
                this.submitted = false;
                this.errores=err.response.data.errors;
            });
        },

    }
}
</script>
<style lang="scss">
    #meses .custom-checkbox{
        width: 135px;
        margin: 5px 0;
    }
</style>